import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import HomeERP from '../components/Banner/ErpBanner';
import ErpService from '../components/Service/ErpService';
import ErpAction from '../components/ErpAction';
import ErpFeatures from '../components/Features/ErpFeatures';
import Erpanalytics from '../components/Erpanalytics';
import Designtab from '../components/Features/Designtab';
import ErpActionTwo from '../components/ErpActionTwo';
import Price from '../components/Price';
import ErpTestimonial from '../components/Testimonial/ErpTestimonial';
import FooterErp from '../components/Footer/FooterErp';
import FooterData from '../components/Footer/FooterData';

export const Home = () => (
    <div className="body_wrapper">
            <CustomNavbar mClass="erp_menu" hbtnClass="er_btn" nClass="mr-auto"/>
            <HomeERP/> 
            <ErpService/>
            <ErpAction/>
            <Erpanalytics/>
            <Price/>
            <ErpTestimonial/>
            <ErpActionTwo/>
            <FooterErp fClass="h_footer_dark_two" FooterData={FooterData}/>
    
    </div>
)