import React from 'react';

const ErpActionTwo =()=> {
    return(
        <section className="erp_call_action_area">
            <div className="container">
                <div className="erp_action_content text-center">
                    <img src={require("../img/erp-home/rocket.png")} alt=""/>
                    <h3>Spoznajte pametnejši način izdajanja računov</h3>
                    <p>Z aplikacijo<span>Račun123</span>je to mogoče</p>
                    <br/><a href="/#" className="hosting_btn btn_hover wow fadeInUp">Registriraj se brezplačno</a>
                </div>
            </div>
        </section>
    )
}
export default ErpActionTwo;