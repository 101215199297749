import React from 'react';
import Sectitle from "./Title/Sectitle";
import ErpanalyticsItem from "./ErpanalyticsItem";

const Erpanalytics =()=>{
    return(
        <section className="erp_analytics_area">
            <div className="container">
                <Sectitle Title="Analitika" TitleP="Prinašamo vam vpogled v vaš posel, za lažje odločanje in vpogled v najboljše stranke, produkte, itd.!"
                 sClass="hosting_title erp_title text-center"/>
                <div className="row">
                    <ErpanalyticsItem analyticsImage="settings1.png" text="Hiter dostop do najboljših produktov"/>
                    <ErpanalyticsItem analyticsImage="cloud-computing.png" text="Popoln vpogled v poslovanje"/>
                    <ErpanalyticsItem analyticsImage="growth.png" text="Kje lahko naredite korak naprej"/>
                </div>
            </div>
        </section>
    )
}
export default Erpanalytics;