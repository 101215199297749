import React from 'react';
import Sectitle from '../Title/Sectitle';
import ErpServiceitem from './ErpServiceitem';

const ErpService =()=> {
    return(
        <section className="erp_service_area sec_pad">
            <div className="container">
                <Sectitle sClass="hosting_title erp_title text-center" Title='Dostopno, Priročno & Varno' TitleP='Ne mučite se z neprijaznimi programi.'/>
                <div className="row">
                    <ErpServiceitem itemClass="pr_70" sTitle="Davčno potrjevanje računov" pText="Davčna Blagajna postane resnično enostavna. Račun123 lahko uporabljate tudi kot davčno blagajno. Račune izdajate po ustaljenem načinu v A4 formatu." icon="erp_icon1.png"/>
                    <ErpServiceitem itemClass="pr_70 pl_10" sTitle="UJP.net e-računi" pText="Če vam tehnologija povzroča težave, je temu konec. Namesto vas za vse digitalne podpise, certifikate itd. poskrbimo MI. Vi pa enostavno KLIK KLIK in E-račun je pri prejemniku." icon="erp_icon2.png"/>
                    <ErpServiceitem itemClass="pl_70" sTitle="Integracija Spletne Trgovine" pText="Bloke fantastic bubble and squeak do one what a plonker nancy boy burke fanny around." icon="erp_icon3.png"/>
                    <ErpServiceitem itemClass="pr_70" sTitle="Izvoz računovodstvu" pText="Pretočite vaše račune in potne naloge računovodji z enim klikom. Luksuz! :)" icon="erp_icon4.png"/>
                    <ErpServiceitem itemClass="pl_10" sTitle="Integracija Vaše Banke" pText="Bloke fantastic bubble and squeak do one what a plonker nancy boy burke fanny around." icon="erp_icon5.png"/>
                    <ErpServiceitem itemClass="pl_70" sTitle="Mobilno izdajanje računov" pText="Narejeno po meri, za hitro in hkrati popolnoma enostavno uporabo tudi na mobilnih napravah. Izdaj e-račun kar na poti ali iz dopusta." icon="erp_icon6.png"/>
                </div>
            </div>
        </section>
    )
}

export default ErpService;