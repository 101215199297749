import React, {Component} from 'react';
import Sectitle from './Title/Sectitle';

class Price extends Component {
    render(){
        return(
            <section className="support_price_area sec_pad">
                <div className="container custom_container p0">
                    <Sectitle sClass="sec_title text-center mb_70" Title='Paketi' TitleP='Sestavljeni na podlagi najpogostejših navad in potreb uporabnikov. Izberi tistega, ki ti ponuja največ za svojo ceno!'/>
                    <div className="price_content price_content_two">
                        <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="price_item">
                                <img src={require ("../img/new-home/p-1.png")} alt=""/>
                                <h5 className="f_p f_size_20 f_600 t_color2 mt_30">ENA</h5>
                                <p>Za preprost in udoben začetek</p>
                                <div className="price f_700 f_size_40 t_color2">€19.90<sub className="f_size_16 f_400">/mesec</sub></div>
                                <ul className="list-unstyled p_list">
                                    <li><i className="ti-check"></i>Do 10 računov</li>
                                    <li><i className="ti-check"></i>Davčno Potrjevanje</li>
                                    <li><i className="ti-check"></i>UJP E-Računi</li>
                                    <li><i className="ti-close"></i>Integracija Spletne Trgovine</li>
                                    <li><i className="ti-close"></i>Integracija Banke</li>
                                </ul>
                                <a href="/#" className="price_btn btn_hover">Začni zdaj</a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="price_item">
                                <div className="tag"><span>Naj Izbira</span></div>
                                <img src={require ("../img/new-home/p-2.png")} alt=""/>
                                <h5 className="f_p f_size_20 f_600 t_color2 mt_30">DVA</h5>
                                <p>Standardni paket za modernega podjetnika</p>
                                <div className="price f_700 f_size_40 t_color2">€29.90<sub className="f_size_16 f_400">/mesec</sub></div>
                                <ul className="list-unstyled p_list">
                                    <li><i className="ti-check"></i>Neomejeno računov</li>
                                    <li><i className="ti-check"></i>Davčno Potrjevanje</li>
                                    <li><i className="ti-check"></i>UJP E-Računi</li>
                                    <li><i className="ti-check"></i>Integracija Spletne Trgovine</li>
                                    <li><i className="ti-close"></i>Integracija Banke</li>
                                </ul>
                                <a href="/#" className="price_btn btn_hover">Začni zdaj</a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="price_item">
                                <img src={require ("../img/new-home/p-3.png")} alt=""/>
                                <h5 className="f_p f_size_20 f_600 t_color2 mt_30">TRI</h5>
                                <p>Premium za zahtevne uporabnike</p>
                                <div className="price f_700 f_size_40 t_color2">€39.90<sub className="f_size_16 f_400">/mesec</sub></div>
                                <ul className="list-unstyled p_list">
                                    <li><i className="ti-check"></i>Neomejeno računov</li>
                                    <li><i className="ti-check"></i>Davčno Potrjevanje</li>
                                    <li><i className="ti-check"></i>UJP E-Računi</li>
                                    <li><i className="ti-check"></i>Integracija Spletne Trgovine</li>
                                    <li><i className="ti-check"></i>Integracija Banke</li>
                                </ul>
                                <a href="/#" className="price_btn btn_hover">Začni zdaj</a>
                            </div>
                        </div>
                        </div> 
                    </div>
                </div>
            </section>
        )
    }
}
export default Price;